import React from 'react';
import './App.css';

const App: React.FC = () => {
  return (
    <div className="App">
      {/* Hero Section */}
      <header className="hero">
        <div className="hero-content">
          <h1>HAMADA's Portfolio</h1>
          <p>Software Engineer | Product Manager</p>
        </div>
      </header>

      {/* About Section */}
      <main>
        <section id="about">
          <h2>About Me</h2>
          <p>HAMADAです！Android開発をメインに、ものづくりに取り組んでいます！</p>
          <ul>
            <li><strong>学年:</strong> 会津大学 学部4年</li>
            <li><strong>出身:</strong> 京都府</li>
          </ul>
        </section>

        {/* Projects Section */}
        <section id="projects">
          <h2>Outputs</h2>
          <div className="project-list">
            <div className="project">
              <h3>Github</h3>
              <p>個人開発などのリポジトリ</p>
              <a href="https://github.com/hamashle" className="btn">View Project</a>
            </div>
            <div className="project">
              <h3>はてなブログ</h3>
              <p>学んだ技術などをアウトプット</p>
              <a href="https://hahahamada.hatenablog.com" className="btn">View Project</a>
            </div>
            <div className="project">
              <h3>SpeakerDeck</h3>
              <p>LTでの登壇資料等の公開</p>
              <a href="https://speakerdeck.com/hamashle" className="btn">View Project</a>
            </div>
          </div>
        </section>

        {/* Skills Section */}
        <section id="skills">
          <h2>Skills</h2>
          <ul className="skills-list">
            <li>Android</li>
            <li>Kotlin</li>
            <li>AWS</li>
          </ul>
        </section>

        {/* Contact Section */}
        <section id="career">
          <h2>略歴</h2>
          <ul>
            <li>
              <strong>2021年4月 ~ 2025年3月 </strong>
              会津大学コンピュータ理工学部コンピュータ理工学科 卒業後大学院進学予定
            </li>
            <li>
              <strong>2023年1月 ~ 現在 </strong>
              Alche株式会社 バックエンド/インフラ エンジニア インターン
            </li>
            <li>
              <strong>2024年1月 ~ 2025年4月 </strong>
              経済産業省 主催 AKATSUKIプロジェクト採択チームでの業務委託
              Androidエンジニア
            </li>
            <li>
              <strong>2024年3月 </strong>
              CA Tech Dojo -Android 編-
            </li>
            <li>
              <strong>2024年7月 ~ 2025年1月終了予定 </strong>
              経済産業省 主催 AKATSUKIプロジェクト採択 (アオタケプロジェクト)
              プロジェクトリーダー兼エンジニアを担当
            </li>
            <li>
              <strong>2024年8月 ~ 2024年9月 </strong>
              LINEヤフー株式会社 Androidエンジニア インターン
            </li>
          </ul>
        </section>
      </main>

      {/* Footer */}
      <footer>
        <p>&copy; 2024 HAMADA's Portfolio. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;
